export const formName = "Форма добавления нового таска.";
export const newTaskText = "Новый таск:";
export const ok = "ОК";
export const createdText = "Cоздал:";
export const emailText = "Email:";
export const statusText = "Статус";
export const taskText = "Текст таска:";
export const imgText = "Картинка";
export const userNameText = "Имя пользователя";
export const adminEditText = "Текст редактирования админом";
export const labelText = "Текст";
export const cancel = "Отмена";
export const preview = "Превью";
export const addTaskText = "Добавить таск";
export const newTaskSuccessAddedMessage = "Новый таск успешно добавлен!";
export const notFoundText = "404 Page";
export const adminLogin = "admin";
export const adminPassword = "123";
export const loginText = "Логин";
export const passwordText = "Пароль";
export const authText = "Авторизоваться";
export const logoutText = "Выйти";
export const authMessage = "Логин или пароль не верны!";
export const onMainPage = "На главную";
export const notRunStatusText = "Не выполнено";
export const runStatusText = "Выполнено";
export const waitingForRunStatusText = "Ожидает выполнения";
export const adminChangedText = "(Изменено админом)";
export const titleText = "Задачник";
export const qtySymbolsForModal = 35;
